import React, { ReactNode, Fragment, useState, useLayoutEffect } from "react";
import { HeadFC } from "gatsby";
import { queryResult } from "../data/graphql/query";
import { getGatsbyImageNode } from "../../utils/getGatsbyImageNode";

import LayoutLibrary from "@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-with-alert-24h";
import ClientAreaButton from "@flyvet/flyvet-react-library/dist/src/components/buttons/client-area-button";

import "../styles/variables.scss";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: any) => {
  const [pageLabel, setPageLabel] = useState("");

  useLayoutEffect(() => {
    let path = window.location.pathname;

    if (path === "/") {
      setPageLabel("home");
    } else {
      setPageLabel(path);
    }
  }, []);

  const {
    companyName,
    city,
    logoTopbar,
    logoFooter,
    facebook,
    instagram,
    linkedin,
  } = queryResult();

  const renderButton24h = (trackEvent: any) => (
    <div style={{ padding: 8 }}>
      <ClientAreaButton
        eventCategory="click_ligar"
        clickEventName="click_plantao"
        onClick={() => trackEvent({ action: "click_plantao" })}
        containerLabel="topbar-button"
        btnLabelTop="Plantao 24H"
        btnLabelBottom="(19) 99728 - 0719"
        clientArealink="tel:+5519997280719"
      ></ClientAreaButton>
    </div>
  );

  return (
    <Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(window, document, script) {
              if (!window.tt) {
                window.tt = window.tt || {};

                c = document.getElementsByTagName('head')[0];
                  k = document.createElement('script');
                  k.async = 1;
                  k.src = script;
                  c.appendChild(k);
              }

              window.tt.accountCode = 'd98988cd-226a-4d1f-8b8e-6ab1da614dfb';
            })(window, document, '//s.tintim.app/static/core/tintim-1.0.js');`,
        }}
      ></script>

      {pageLabel === "/ebook" && <Fragment>{children}</Fragment>}

      {pageLabel !== "/ebook" && (
        <LayoutLibrary
          phone="5519997280719"
          renderButton24h={renderButton24h}
          logoTopbar={getGatsbyImageNode(
            logoTopbar,
            `Logo ${companyName}`,
            true,
            false,
            false
          )}
          logoFooter={getGatsbyImageNode(
            logoFooter,
            `Logo ${companyName}`,
            true,
            true,
            true
          )}
          companyName={companyName}
          city={city}
          slogan={"Uma amizade animal"}
          badge={{ type: "vet" }}
          facebook={facebook}
          instagram={instagram.user}
          linkedin={linkedin}
          navItems={[
            {
              label: "Home",
              path: "/",
            },
            {
              label: "Serviços",
              path: "/servicos",
            },
            {
              label: "Galeria",
              path: "/galeria",
            },
            {
              label: "Contato",
              path: "/contato",
            },
            {
              label: "E-book",
              path: "/ebook",
            },
          ]}
        >
          {children}
        </LayoutLibrary>
      )}
    </Fragment>
  );
};

export default Layout;

export const Head: HeadFC<{}> = ({}) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-700.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
);
